.referenz {
  height: 220px;
  border: 2px solid #eaeaea;
  padding: 20px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  vertical-align: middle;

  .referenz-image {
    padding-top: 50px;
    width: 180px;
    display: flex;
    text-align: center;
    top: 35%;
  }
  img {    
    width: 100%;    
    height: auto;
  }  
}
