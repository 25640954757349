.service-summary {
  display: flex;
  align-items: flex-start;
  .service-image {
    flex: 0 0 100px;
    margin-right: 10px;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .service-content {
    flex: 1;
    .service-title {
      font-family: $font-family-heading;
      font-size: 1.3rem;
    }
    p {
      color: $secondary;
    }
  }
}
