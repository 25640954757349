@charset "utf-8";


// Bootstrap
@import 'bootstrap/functions';
@import 'bootstrap-variables';
@import 'bootstrap/variables';
@import 'bootstrap/mixins';

// Libraries
@import 'libraries/hamburgers/hamburgers';

// Components
@import 'components/page';
@import 'components/header';
@import 'components/footer';
@import 'components/sub-footer';
@import 'components/logo';
@import 'components/main-menu';
@import 'components/main-menu-mobile';
@import 'components/social';
@import 'components/hamburger';
@import 'components/buttons';
@import 'components/title';
@import 'components/content';
@import 'components/intro';
@import 'components/strip';
@import 'components/whitebox';
@import 'components/feature';
@import 'components/referenz';
@import 'components/partner';

@import 'bootstrap/bootstrap.min.css';

// Pages
@import 'pages/home';
@import 'pages/team/team-list';
@import 'pages/team/team-summary';
@import 'pages/team/page-team-list';
@import 'pages/testimonials/testimonials-list';
@import 'pages/testimonials/testimonials-summary';
@import 'pages/testimonials/page-testimonials-list';
@import 'pages/services/page-services-list';
@import 'pages/services/page-services-single';
@import 'pages/services/service-single';
@import 'pages/services/service-summary';

@import 'fork-awesome/css/fork-awesome.min.css';
@import 'styles/inter.css';
html { 
  font-family: 'Inter', sans-serif;
  -webkit-font-feature-settings: "salt" on, "frac" off, "liga" on, "calt" on, "tnum" on, "ss01" on, "ss03" on, "ss02" on;
  -moz-font-feature-settings: "salt" on, "frac" off, "liga" on, "calt" on, "tnum" on, "ss01" on, "ss03" on, "ss02" on;   
  -ms-font-feature-settings: "salt" on, "frac" off, "liga" on, "calt" on, "tnum" on, "ss01" on, "ss03" on, "ss02" on;
  font-feature-settings: "salt" on, "frac" off, "liga" on, "calt" on, "tnum" on, "ss01" on, "ss03" on, "ss02" on;
}

.background-secondary {
  background-color: $secondary;
}

.background-light {
  background-color: #a2c536;
}

.kis {
  color: $secondary !important;
}

.block {
  text-align: justify;
}

.more_docs {
  margin-top: 50px;
  margin-left: 20px;
  width: 300px;
}

.bold {
  font-weight: bold !important;
}

.shadow {
  box-shadow: 2px 2px 5px 0px rgba(0,0,0,.24) !important;
}

.support-headline {
  font-weight: bold;
}

.support-text {
  padding: 50px;
  font-size: 16px;
}

.nav-item a:hover {
  color: white !important;
}

a {
  color: #333333;
  text-decoration: none;
}

.dropdown-menu a:hover {
  color: black !important;
}

.content a:hover {
  color: black !important;
}

// Display breakpoints for DEV
// body:after {
//   color: #000000;
//   font-size: 12px;
//   padding: 5px;
//   font-weight: bold;
//   right: 10px;
//   position: fixed;
//   text-align: center;
//   text-transform: uppercase;
//   bottom: 10px;
//   width: 200px;
//   z-index: 9999;
//   border: solid 1px #000000;
//   @each $name, $value in $grid-breakpoints {
//     @include media-breakpoint-up($name) {
//       content: "#{$name} - min-width: #{$value}";
//     }
//   }
// }
.nav-link {
   height: inherit;
   display: inline-block;
   text-transform: uppercase !important;
   font-size: 0.9rem;
   font-weight: 400;
   color: inherit;
   padding: 10px 14px 10px 14px;
   margin: 5px;
}

.blog-date {
  float:left;
  width: 60px;
  height: 200px;
  margin-left: -50px;
  margin-right: 10px;
  .day {
    margin-top: -10px;
    font-weight: bold;
    font-size: 30px;
  }
  .month {
    text-transform: uppercase !important;
    font-weight: thin;
    font-size: 25px;
  }
}

.post_tags {
  font-weight: bolder;
  font-size: 13px;

  .tag {
      font-weight: lighter;
      font-size: 13px;
  }
}

.pagination {
  margin-bottom: 50px;
  float:right;
  font-size: 15px;
  .page-link {
     color: #000000;
     text-decoration: none;
  }
  .page-item {
  }
  .selected {
     a {
       color: #198754;
     }
  }
}

.more_docs {
  .tag {
    font-size: 13px;
    padding-right: 5px;
  }
  .selected {
    color: #198754 !important;
  }
  .contact {
    font-size: 13px;
  }
}

 /*! div style */
.image-gallery {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(300px, 1fr));
  justify-content: center;
  padding: 4px;
}

.box {
   flex-basis: 25%;
   width: 100%;
   padding: 10px;
   margin: 2px;
}

.img-gallery {
  width: 100%;
  height: 250px;
  object-fit: cover;
  transform: scale(1);
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
}

a[rel=external] { 
  border-bottom-style: dashed; 
  border-bottom-width: 1px;
  position: relative;
  text-decoration: none;  
}

a[rel=external]:after {
  content: "External Link";
  position: absolute;
  bottom: 130%;
  left: 20%;
  background: #a2c536;
  padding: 5px 15px;
  color: black;
  -webkit-border-radius: 10px;
  -moz-border-radius : 10px;
  border-radius : 10px;
  white-space: nowrap;
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -moz-transition : all 0.4s ease;
  transition : all 0.4s ease;
}

a[rel=external]:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 20px solid #a2c536;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  -webkit-transition: all 0.4s ease;
  -moz-transition : all 0.4s ease;
  transition : all 0.4s ease;
  opacity: 0;
  left: 30%;
  bottom: 90%;
}

a[rel=external]:hover:after {
  bottom: 100%;
}

a[rel=external]:hover:before {
  bottom: 70%;
}

a[rel=external]:hover:after, a:hover:before {
  opacity: 1;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #1e2125 !important;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #a2c536;
}

.userimage {
  margin-right: 20px;
  position: relative;
  width: 100px;
  height: 100px;
  border: 3px solid #fff;
  border-radius: 100%;
}